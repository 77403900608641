<template>
  <div class="PersonalInfo">
    <div class="PersonalInfo-comp">
      <div class="PersonalInfo-comp-center PersonalInfo-m">
        <div class="spin-nested-loading">
          <div class="spin-PersonalInfo">
            <div class="game-list" v-if="UserInfoAll">
              <h2>{{ UserInfoAll.nickname }}</h2>
              <div class="flex-keep">
                <el-button type="primary" @click="ModifyPassword"
                  >修改密码</el-button
                >
                <el-button type="primary" @click="ModifyInfo"
                  >修改信息</el-button
                >
                <!-- <el-button @click="LogOut" type="danger">退出登录</el-button> -->
              </div>
              <div class="rowInfo">
                <div class="row">
                  <span class="submission-item column">姓名</span>
                  <span class="twelve column">{{ UserInfoAll.realname }}</span>
                </div>
              </div>
              <div class="rowInfo">
                <div class="row">
                  <span class="submission-item column">电话</span>
                  <span class="twelve column">{{ UserInfoAll.username }}</span>
                </div>
              </div>
              <div class="rowInfo">
                <div class="row">
                  <span class="submission-item column">权限</span>
                  <span class="twelve column">{{ UserInfoAll.rolename }}</span>
                </div>
              </div>
              <div class="rowInfo">
                <div class="row" v-if="UserInfoAll.referral_code">
                  <span class="submission-item column">推荐码</span>
                  <span class="twelve column">{{
                    UserInfoAll.referral_code
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="修改个人信息" :visible.sync="dialogVisible" width="60%">
      <el-form
        :model="update_info"
        :rules="rules"
        ref="update_info"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="realname">
              <el-input v-model="update_info.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="昵称" prop="nickname">
              <el-input v-model="update_info.nickname"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="电话" prop="username">
              <el-input v-model="update_info.username"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelModifyInfo">取 消</el-button>
        <el-button type="primary" @click="ConfirmModifyInfo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改个人密码"
      :visible.sync="PasswordDialogVisible"
      width="60%"
    >
      <el-form
        :model="Form"
        :rules="rulesForm"
        ref="Form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧密码" prop="old_password">
          <el-input
            type="password"
            v-model="Form.old_password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="new_password">
          <el-input
            type="password"
            v-model="Form.new_password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirm_password">
          <el-input
            type="password"
            v-model="Form.confirm_password"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelModifyPassword">取 消</el-button>
        <el-button type="primary" @click="ConfirmModifyPassword"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { change_password, update_user, getInfo } from "../../api/user.js";
export default {
  name: "PersonalInfos",
  data() {
    //确认密码验证
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.Form.new_password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      UserInfoAll: null,
      dialogVisible: false, //修改个人信息弹窗状态
      update_info: {}, //修改个人信息表单
      // 修改个人信息表单验证
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        realname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" }
        ],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }]
      },
      PasswordDialogVisible: false, //修改个人密码
      Form: {
        old_password: "",
        new_password: "",
        confirm_password: ""
      }, //修改密码表单
      rulesForm: {
        //修改密码验证
        old_password: [
          { required: true, message: "请输入旧密码", trigger: "blur" }
        ],
        new_password: [
          { required: true, message: "请输入新密码", trigger: "blur" }
        ],
        confirm_password: [
          { required: true, validator: validatePass, trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.getInfodata();
  },
  watch: {
    $route(route) {
      if (route.path == "/login") {
        this.$destroy(this.$options.name);
      }
    }
  },
  methods: {
    // 获取用户信息
    getInfodata() {
      getInfo().then(res => {
        this.UserInfoAll = JSON.parse(JSON.stringify(res.data));
        this.$forceUpdate();
      });
    },
    // 登出操作
    LogOut() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$store
            .dispatch("FedLogOut", this.loginForm)
            .then(() => {
              this.$router.push({
                path: this.redirect || "/login"
              });
            })
            .catch(() => {
              this.loading = false;
            });
          this.$message({
            type: "success",
            message: "退出登录!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出"
          });
        });
    },
    // 修改个人信息事件
    ModifyInfo() {
      this.update_info = JSON.parse(JSON.stringify(this.UserInfoAll));
      this.dialogVisible = true;
    },
    // 取消修改个人信息
    cancelModifyInfo() {
      this.update_info = {};
      this.dialogVisible = false;
    },
    // 确定修改个人信息
    ConfirmModifyInfo() {
      delete this.update_info.username;
      this.$refs["update_info"].validate(valid => {
        if (valid) {
          update_user({ update_info: this.update_info }).then(res => {
            if (res.code == 200) {
              this.dialogVisible = false;
              this.$message({
                message: "个人信息修改成功",
                type: "success"
              });
              this.getInfodata();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 修改密码
    ModifyPassword() {
      this.PasswordDialogVisible = true;
    },
    // 取消修改密码
    cancelModifyPassword() {
      this.Form = {};
      this.PasswordDialogVisible = false;
    },
    // 确认修改密码
    ConfirmModifyPassword() {
      this.$refs["Form"].validate(valid => {
        if (valid) {
          change_password(this.Form).then(res => {
            if (res.code == 200) {
              this.$message({
                message: "密码修改成功",
                type: "success"
              });
              this.PasswordDialogVisible = false;
              this.$store
                .dispatch("FedLogOut", {})
                .then(() => {
                  this.$router.push({
                    path: this.redirect || "/login"
                  });
                })
                .catch(() => {});
            }
          });
        } else {
          return false;
        }
      });
    },
    Preview() {},
    Remove(file, fileList) {
      this.FileList = fileList;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
@media (min-width: 1400px) {
  .PersonalInfo-m {
    width: 1400px;
  }
}
@media (min-width: 992px) {
  .PersonalInfo-m {
    width: 85.7%;
  }
}
</style>
